@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SUIT';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

html,body, #root {
  @apply !w-full !h-full;
  --toastify-toast-width: 420px;
  --toastify-toast-max-height: 80px;
  --toastify-z-index: 9999;

  --toastify-color-info: #3498db;
  --toastify-color-success: #47B50B;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;

  --toastify-icon-color-info: #0093FF !important;
  --toastify-icon-color-success: #47B50B !important;
  --toastify-icon-color-warning: #EA2C4E !important;
  --toastify-icon-color-error: var(--toastify-color-error) !important;

}

* {
  font-family: 'SUIT', serif !important;
}

.H1 {
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -0.4px;
}
.H2 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.4px;
}
.H3 {
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
}
.H4 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.4px;
}

.SubH1 {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.4px;
}
.SubH2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.4px;
}
.SubH3 {
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.4px;
}
.Body1 {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
}
.Body2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.4px;
}
.Body3 {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4px;
}
.Body4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4px;
}
.Body5 {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.4px;
}
.Caption {
  font-size: 11px;
  font-weight: 400;
  letter-spacing: -0.4px;
}

.contentBox {
  width: calc(100% - 200px);
  padding: 40px 160px 0 60px;
}


.dropdownWrap {
  @apply max-w-[476px] py-2.5 px-3 hover:bg-Blue-50;
}
.dropdownLi {
  @apply max-w-[476px] w-full Body4 text-Gray-80;
}

[type="radio"] {
  @apply accent-Blue-100;
}

.addItemTableTh {
   @apply px-3 py-4 border border-Gray-40 text-center bg-Gray-20 SubH2;
}
.addItemTableTd {
   @apply px-3 py-4 border border-Gray-40 text-center Body4 h-[72px];
}

.tableTd {
  @apply px-3 py-4;
}

.textEll {
  /* display:-webkit-box; */
  overflow:hidden;
  text-overflow:ellipsis;
  -webkit-line-clamp:2;
  -webkit-box-orient:vertical;
}

.visibleScroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.4);
}

.visibleScroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}

.Toastify__toast {
  @apply rounded-lg !important;
}

.Toastify__toast--info {
  @apply bg-Gray-10 border border-Blue-100 !important;
}
.Toastify__toast--error {
  @apply bg-Gray-10 border border-Red100 !important;
}
.Toastify__toast--success {
  @apply bg-GreenTint90 border border-Green100 !important;
}

.Toastify__toast {
  @apply whitespace-pre-line !important;
}

.hoverContainer {
  @apply relative;
}

.hoverContainer:hover > .item,
.hoverContainer:active > .item {
  @apply flex w-[120px];
}

input:disabled {
  @apply bg-Gray-20;
}
textarea:disabled {
  @apply bg-Gray-20;
}

textarea:focus {outline:none;}
input:focus {outline:none;}


/* .imp-dialog.pc .imp-frame-danal-certification {
  @apply !mx-auto !mt-0 !top-2/4 -translate-y-2/4;
} */
.imp-dialog.pc.certification-danal .imp-header .imp-close  {
  @apply !top-[28px];
}

.slick-next {
  right: -60px !important;
  z-index: 100 !important;
}
.slick-prev {
  left: -60px !important;
  z-index: 100 !important;
}